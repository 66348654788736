<template>
<div>
    <v-card class="display-block" flat>
        <h3>Modify Coupon</h3>
        <v-card-text>
            <alert-message v-if="msg" :error="msg"></alert-message>
            <v-form ref="form" lazy-validation>
                <v-layout wrap>
                    <v-flex :class="coupon.code?'sm12':'sm9'" mx-2>
                        <v-text-field label="Coupon Code*" v-model="coupon.code"></v-text-field>
                    </v-flex>
                    <v-flex :class="coupon.code?'':'sm2'" pt-4>
                        <v-btn :disabled="loading" :loading="loading" v-if="!coupon.code" class="mr-4" :style="theme" @click="generateCode()">Generate Code</v-btn>
                    </v-flex>
                    <v-flex xs12 sm6>
                        <v-select class="mx-2" label="Coupon Type*" v-model="coupon.couponType" :items="couponType" :rules="couponTypeRules"></v-select>
                    </v-flex>
                    <v-flex xs12 sm6>
                        <v-select class="mx-2" v-if="coupon.couponType=='SPLUSR'" label="User*" v-model="coupon.user" :items="users" item-text="displayName" return-object :rules="fieldRequired"></v-select>
                        <v-select class="mx-2" v-if="coupon.couponType=='PRODUCT'" label="Product*" v-model="coupon.product" :items="products" item-text="displayName" return-object :rules="fieldRequired"></v-select>
                        <v-select class="mx-2" v-if="coupon.couponType=='BRAND'" label="Brand*" v-model="coupon.brand" :items="brands" item-text="displayName" return-object :rules="fieldRequired"></v-select>
                        <v-select class="mx-2" v-if="coupon.couponType=='CATEGORY'" label="Category*" v-model="coupon.category" :items="categories" item-text="displayName" return-object :rules="fieldRequired"></v-select>
                    </v-flex>
                    <v-flex xs12 sm6>
                        <v-select class="mx-2" label="Discount Type*" v-model="coupon.discountType" :items="discountType" :rules="discountTypeRules"></v-select>
                    </v-flex>
                    <v-flex xs12 sm6>
                        <v-text-field class="mx-2" v-if="coupon.discountType=='PERCENT'" label="Percent*" v-model.number="coupon.percent" :rules="valueFieldRules"></v-text-field>
                        <v-text-field class="mx-2" v-else label="Amount*" v-model.number="coupon.amount" :rules="valueFieldRules"></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6>
                        <v-text-field class="mx-2" label="Max Discount Amount*" v-model.number="coupon.maxDiscountAmount" :rules="valueFieldRules"></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6>
                        <v-text-field class="mx-2" label="Max Usage*" v-model.number="coupon.maxUsageOfCoupon" :rules="valueFieldRules"></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6>
                        <v-text-field class="mx-2" label="Minimun Order Amount*" v-model.number="coupon.minOrderAmount" :rules="valueFieldRules"></v-text-field>
                    </v-flex>
                    <v-flex xs6 sm3>
                        <input-date class="mx-2" label="Start Date" v-model="startDate"></input-date>
                    </v-flex>
                    <v-flex xs6 sm3>
                        <input-date class="mx-2" label="Expiry Date" v-model="expiryDate"></input-date>
                    </v-flex>
                </v-layout>
                <v-flex>
                    <v-checkbox v-model='coupon.isActive' label="Active"></v-checkbox>
                </v-flex>
                <v-flex>
                    <v-checkbox v-model='coupon.showOnStore' label="Show On WebStore"></v-checkbox>
                </v-flex>
                <alert-message v-if="msg" :error="msg"></alert-message>
                <v-flex text-right>
                    <v-btn class="primary-inverted mr-4" :style="theme" :loading="loading" :disabled="loading" @click="submit()">Save</v-btn>
                    <v-btn class="mr-4" :style="themeInverted" outlined @click="$router.go(-1)">Cancel</v-btn>
                </v-flex>
            </v-form>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import moment from 'moment'
import appConstants from '@/utils/appConstants'
import voucher_codes from "voucher-code-generator"
export default {
    data() {
        return {
            coupon: {
                code: "",
                couponType: '',
                startDate: Date,
                expiryDate: '',
                discountType: '',
                percent: 0,
                amount: 0,
                maxDiscountAmount: 0,
                vendorId: '',
                user: {},
                maxUsageOfCoupon: 0,
                product: {},
                brand: {},
                category:{},
                minOrderAmount: 0,
                isActive: true,
                showOnStore:true
            },
            couponTypeRules: [v => !!v || 'Coupon Type is required'],
            discountTypeRules: [v => !!v || 'Discount Type is required'],
            valueFieldRules: [v => !!v || 'Value is required'],
            couponType: ['SPLUSR', 'GENRAL', 'PRODUCT', 'BRAND','CATEGORY'],
            discountType: ['PERCENT', 'AMOUNT'],
            users: [],
            products: [],
            brands: [],
            categories:[],
            id: 0,
            loading: false,
            startDate: '',
            expiryDate: '',
            prefix: ''
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.coupon.vendorId = this.getUserProfile().vendorId
            this.users = await this.getItem(appConstants.CUSTOMERS_API + "/refList")
            this.products = await this.getItem(appConstants.PRODUCTS_API + "/refList")
            this.brands = await this.getItem(appConstants.BRANDS_API + "/refList")
            this.categories = await this.getItem(appConstants.CATEGORIES_API + "/refList")
            if (this.id != 0) {
                this.coupon = await this.getItem(appConstants.COUPONS_API + "/" + this.id)
                this.startDate = moment(this.coupon.startDate).format("YYYY-MM-DD")
                this.expiryDate = moment(this.coupon.expiryDate).format("YYYY-MM-DD")
            }
        },
        submit() {
            if (this.$refs.form.validate()) {
                this.loading = true
                this.convertToDate()
                if (this.id == 0) {
                    this.saveItem()
                } else {
                    this.updateItem()
                }
            }
        },
        async saveItem() {
            try {
                this.coupon = await this.postItem(appConstants.COUPONS_API, this.coupon)
                this.$router.push({ name: "Coupons" })
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
                this.loading = false
            }
        },
        async updateItem() {
            try {
                await this.putItem(appConstants.COUPONS_API + "/" + this.coupon._id, this.coupon)
                this.$router.push({ name: "Coupons" })
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data.errors
                this.loading = false
            }
        },

        /**
         * This method generates coupon code
         */
        generateCode() {
            this.createPrefix()
            var code = voucher_codes.generate({
                prefix: this.prefix,
                length: 6,
                count: 1
            });
            this.coupon.code = code[0]
            //this.submit()
        },

        /**
         * This method converts date to ISOFormat
         */
        convertToDate() {
            var startDateParts = this.startDate.split("/");
            this.coupon.startDate = new Date(+startDateParts[2], startDateParts[1] - 1, +startDateParts[0]);
            var expiryDateParts = this.expiryDate.split("/");
            this.coupon.expiryDate = new Date(+expiryDateParts[2], expiryDateParts[1] - 1, +expiryDateParts[0]);
        },

        /**
         * Creates Coupon Prefix
         */
        createPrefix() {
            var words = this.$store.getters.vendor.businessName.split(" ")
            this.prefix = 'CP-'
            if (words.length == 1)
                this.prefix = words[0].substring(0, 1).toUpperCase() + "-"
            else
                this.prefix = words[0].substring(0, 1).toUpperCase() + "" + words[1].substring(0, 1).toUpperCase() + "-"
        }
    },
}
</script>

<style scoped>
.row-padding {
    padding: 0px;
}
</style>
